exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cadeaux-js": () => import("./../../../src/pages/cadeaux.js" /* webpackChunkName: "component---src-pages-cadeaux-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koalinews-js": () => import("./../../../src/pages/koalinews.js" /* webpackChunkName: "component---src-pages-koalinews-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-prestations-accompagnements-js": () => import("./../../../src/pages/prestations/accompagnements.js" /* webpackChunkName: "component---src-pages-prestations-accompagnements-js" */),
  "component---src-pages-prestations-consultheure-js": () => import("./../../../src/pages/prestations/consultheure.js" /* webpackChunkName: "component---src-pages-prestations-consultheure-js" */),
  "component---src-pages-prestations-sites-web-js": () => import("./../../../src/pages/prestations/sites-web.js" /* webpackChunkName: "component---src-pages-prestations-sites-web-js" */),
  "component---src-pages-qui-sommes-nous-js": () => import("./../../../src/pages/qui-sommes-nous.js" /* webpackChunkName: "component---src-pages-qui-sommes-nous-js" */),
  "component---src-pages-ugliastru-js": () => import("./../../../src/pages/ugliastru.js" /* webpackChunkName: "component---src-pages-ugliastru-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-client-js": () => import("./../../../src/templates/client.js" /* webpackChunkName: "component---src-templates-client-js" */),
  "component---src-templates-freebie-js": () => import("./../../../src/templates/freebie.js" /* webpackChunkName: "component---src-templates-freebie-js" */),
  "component---src-templates-lp-js": () => import("./../../../src/templates/lp.js" /* webpackChunkName: "component---src-templates-lp-js" */)
}

